import { FC, useState } from 'react'
import { Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import useModal from 'hooks/useModal'

import CreateSpaceForm from './CreateSpaceForm'

type Props = {
  workspaceId: number
}

const CreateSpaceModal: FC<Props> = ({ workspaceId }) => {
  const { isVisible, openModal, closeModal } = useModal()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <button className="justify-center items-center [&>span]:!m-0 pl-1 flex gap-2 font-medium" onClick={openModal}>
        <PlusOutlined />
        <div>Create spaces</div>
      </button>
      <Modal
        open={isVisible}
        key="conversation-starters-create-modal"
        title="Configure your space"
        wrapClassName="overflow-hidden"
        className="top-0 max-md:!max-w-full max-md:left-0 bottom-0 h-full max-md:!w-full max-md:!m-0 md:top-[1.75vh] [&_.ant-modal-content]:lg:max-h-[95vh] [&_.ant-modal-content]:max-md:!rounded-none [&_.ant-modal-content]:overflow-auto"
        onCancel={closeModal}
        okText="Save"
        cancelButtonProps={{ size: 'large', disabled: isLoading }}
        okButtonProps={{
          form: 'create_space_form',
          htmlType: 'submit',
          size: 'large',
          loading: isLoading,
          onClick: (e) => e.stopPropagation()
        }}
        destroyOnClose
        loading={isLoading}
      >
        <CreateSpaceForm workspaceId={workspaceId} setIsLoading={setIsLoading} onSubmit={closeModal} />
      </Modal>
    </>
  )
}

export default CreateSpaceModal
