export enum AppRoutes {
  main = '/',
  alerts = '/alerts',
  appSettings = '/app-settings',
  feedbacks = '/feedbacks',
  login = '/login',
  oneTimeLink = '/one-time-link',
  oneTimeToken = '/one-time-token',
  test = '/test',
  workspaces = '/workspaces',
  userInvite = '/user/invite',
  dashboard = '/dashboard',
  settings = '/settings',
  policy = '/privacy-policy',
  terms = '/terms-and-conditions',
  conversationStartersManagement = '/conversation-starters-management',
  systemAdminDashboard = '/system-admin-dashboard',
  productManagement = '/product-management'
}
